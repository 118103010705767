@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;500;700&display=swap");

:root {
  --white: #ffffff;
  --black: #21171b;
  --gray-50: #333;
  --divider-bar: #c7c7c7;
  --green-50: #96eea0;
  --green-200: #44b44f;
  --purple-50: #d7799d;
  --primary: #ff9668;
  --orange-50: #f2c5ac;
  --title-color: #924141;
  --h1: 4.8rem;
  --h2: 3.2rem;
  --subtitle: 2.4rem;
  --p: 1.8rem;
  --sub: 1.6rem;
  --small: 1.4rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Manrope", sans-serif;
  color: var(--black);
  font-size: var(--p);
}

header > a,
.btn-cta {
  background: var(--primary);
  color: var(--white);
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: 0;
  height: 3rem;
  font-size: var(--p);
  cursor: pointer;
  transition: ease-in 0.2s;
  border-radius: 9999px;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
}

ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

ul li {
  list-style: none;
  font-weight: 400;
}

ul li a {
  text-decoration: none;
  background: transparent;
  color: var(--black);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black);
  position: relative;
}

main,
section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main {
  background-image: url("./img/Ar-livre.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.logo {
  width: 18%;
  height: auto;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

section,
.col {
  flex-direction: row;
  justify-content: center;
  padding: 4rem 8%;
}

.col {
  flex-direction: column;
}

section:nth-child(2n + 2) {
  background-color: var(--orange-50);
}

.cta {
  text-transform: uppercase;
}

/* https://css-stripe-generator.firebaseapp.com/ */

.img {
  height: 40%;
  background-size: auto auto;
  background-color: rgba(255, 255, 255, 1);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    hsla(18, 100%, 80%, 0.295) 10px,
    hsla(18, 100%, 80%, 0.295) 20px
  );
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

header {
  /* position: fixed;
  top: 0;
  z-index: 9999; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 8%;
  background: #fff;
}

.hero {
  width: 100%;
  height: 100%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper h1 {
  font-size: 5rem;
  font-weight: 700;
  font-size: 5.2rem;
  background: linear-gradient(90deg, #ba2b12 0%, #c92c41 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wrapper span {
  color: var(--primary);
  background: var(--white);
  padding: 0.5rem 1rem;
  font-weight: 700;
  border-radius: 9999px;
}

h1 {
  color: var(--black);
  font-weight: 700;
  font-size: var(--h1);
  line-height: 120%;
}

.title {
  text-align: center;
  width: 100%;
  padding: 3rem 8%;
}

.subtitle {
  font-size: var(--subtitle);
}

.resume p {
  color: var(--gray-50);
  font-weight: 700;
  font-size: var(--small);
}

header > a,
.wrapper a,
.resume a,
.services a {
  background: var(--primary);
  color: var(--white);
  font-weight: 700;
  padding: 2.5rem;
  border: 0;
  height: 3rem;
  width: max-content;
  cursor: pointer;
  transition: ease-in 0.2s;
  border-radius: 9999px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--small);
}

.resume a {
  margin: 2rem 0;
}

.wrapper a:hover,
.resume a:hover,
.services a:hover {
  filter: brightness(0.9);
}

/* about */

.about {
  padding: 0;
}

.resume {
  width: 60%;
  padding: 4rem 8%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;
}

section span {
  letter-spacing: 1rem;
  text-transform: uppercase;
}

.resume h1,
.services h1 {
  font-size: var(--h1);
}

.resume p {
  line-height: 3rem;
}

.img {
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.img img {
  border-radius: 9999px 9999px 18px 18px;
  border: 4px solid var(--white);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
  width: calc(100% - 10rem);
  height: calc(100% - 15rem);
}

.approach {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.data > div {
  padding: 3rem 8%;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.data h1 {
  font-size: var(--h1);
  margin: 2rem 0;
  text-align: center;
}

.data .numbers {
  width: 100%;
  max-width: 950px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.data .numbers h2 {
  font-size: var(--h2);
}

.highlight {
  font-size: var(--p);
}

.data .numbers .highlight {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  color: var(--primary);
}

.data .numbers .highlight span {
  color: var(--gray-50);
}

.data .numbers strong {
  color: var(--black);
}

.data .divider {
  width: 1px;
  background: var(--divider-bar);
}

/* reasons */

.reasons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.reasons .line-3 {
  width: 100%;
  display: flex;
  gap: 2rem;
}

.reasons .reason-item {
  width: 33.3333%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--divider-bar);
  border-radius: 8px;
  padding: 3rem;
}

/* depoiments */
#depoiments {
  display: none;
}

/* services */

.services {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 5rem 0;
  gap: 3rem;
}

.services .parent {
  display: flex;
  gap: 4rem;
  width: 100%;
  max-width: 950px;
}

.services .parent .bg-grid {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 25rem;
  border-radius: 10px;
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.services .parent .bg-grid img {
  width: 33.3333%;
}

#adultos {
  background-image: url("./img/adultos.jpg");
}

#adolescente {
  background-image: url("./img/adolescentes.jpg");
}

#parental {
  background-image: url("./img/parental2.jpg");
}

.filter {
  filter: grayscale(100%);
  transition: 0.2s ease-in;
}

.filter:hover {
  filter: none;
  transform: translate(5px, 5px);
}

.grid .left,
.grid .right {
  width: 50%;
}

.grid .left img {
  width: 90%;
  height: 410px;
  border-radius: 8px;
  background-size: cover;
}

.more-details {
  position: absolute;
  bottom: 1rem;
  font-weight: 700;
  color: var(--black);
  background-color: var(--white);
  height: 40px;
  width: 80%;
  border-radius: 9999px;
  padding: 1rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  width: 100%;
  max-width: 950px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid .right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.grid .right .items {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 4px;
  transition: ease-in 0.3s;
  cursor: pointer;
}

.grid .right .items:hover {
  background: var(--orange-50);
}

.grid .right .items p {
  font-size: var(--p);
  color: var(--gray-50);
  font-weight: bold;
}

.grid .right .items h1 {
  width: 100%;
  margin: 0;
}

.contact {
  gap: 2rem;
}

.spacing {
  gap: 2rem;
}

form {
  width: 90%;
  padding: 4rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
}

form button {
  border: 0;
  max-width: max-content;
  background-color: var(--primary);
  color: var(--white);
  padding: 1.5rem 3rem;
  cursor: pointer;
  font-weight: bold;
  transition: 0.2s ease-out;
}

form button:hover {
  filter: brightness(0.9);
}

input,
textarea,
button {
  width: 100%;
  margin: 1.6rem 0;
  padding: 1.6rem;
  font-size: var(--p);
  border: 1px solid var(--divider-bar);
  font-family: "Manrope", sans-serif;
  font-weight: 500;
}

textarea {
  resize: none;
}

footer {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--small);
  padding: 4rem 8%;
}

.op-class {
  opacity: 0;
}
.fadeIn {
  opacity: 1;
  transition: opacity 3s;
}

.details-contact span {
  all: unset;
  font-size: var(--small);
  font-weight: 700;
  color: var(--gray-50);
}

.details-contact {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  color: var(--black);
}

.whatsapp-btn {
  width: 90px;
  height: 90px;
  position: fixed;
  bottom: 0;
  right: 0;
}

.dtn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s;
  border-radius: 9999px;
  width: 350px;
}

.dtn:hover {
  margin-left: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.dtn img {
  width: 65px;
  height: 65px;
}

.mail img {
  width: 40px;
  height: 40px;
  margin: 0 12px;
}

.dtn a {
  background: none;
  color: var(--black);
  padding: 0;
}

/* mobile */

@media (max-width: 900px) {
  h1 {
    font-size: 2rem;
  }

  section {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  span {
    font-size: var(--sub) !important;
  }

  h1 {
    font-size: var(--h2) !important;
  }

  footer p {
    font-size: var(--small) !important;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
    text-align: center;
  }

  p {
    font-size: 1.8rem;
  }

  section p {
    text-align: justify;
  }

  .nav-mobile {
    flex-direction: column;
    gap: 2rem;
    background: #fff;
    width: 200px;
  }

  ul {
    display: none;
  }

  .logo {
    width: 50%;
  }

  .btn-header {
    margin-bottom: 2rem;
  }

  .wrapper .flex svg,
  .depoiments {
    display: none;
  }

  .services,
  .services .parent,
  .reasons,
  .line-3 {
    display: flex;
    flex-direction: column;
  }

  .about,
  .resume,
  .img,
  .data,
  .col,
  .reasons,
  .line-3 {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .reasons .reason-item {
    width: 100%;
  }

  .img {
    padding: 2rem 0;
  }

  .numbers,
  .highlight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  form {
    padding: 2rem;
  }

  footer {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
